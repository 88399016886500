@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*,
body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Inter';
}

.bg-background {
  background-color: #fff;
}
.burger-menu-icon-div {
  text-align: right;
  width: 50%;
}
.main-log-img {
  flex-direction: column-reverse;
  align-items: flex-start;
  align-self: center;
  justify-self: flex-start;
  display: flex;
  position: static;
  right: 20px;
}
.MuiMenuItem-gutters {
  display: flex !important;
  padding: 10px !important;
}
.burger-menu-icon-div .burger-menu-icon svg {
  cursor: pointer;
  height: auto;
  color: #fff;
  width: 30px;
}
.top-header {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.having-border > div {
  border-radius: 15px;
}
.loader-main-div {
  max-width: 600px;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 580px;
  align-items: center;
}
.loaderMainDiv {
  max-width: 100%;
  height: 100vh;
  position: relative;
  background: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
  align-items: center;
  opacity: 0.95;
  top: 0;
  left: 0;
  display: flex;
}
.mainLoaderDiv span {
  width: 80px !important;
  height: 80px !important;
}
.mainLoaderDiv span svg {
  color: #192733 !important;
}
.activeTab {
  background: #41a6d5 !important;
  color: #ffff !important;
  /* font-weight: bold; */
}
.main-holder-container {
  position: relative;
  padding: 15px !important;
  background: #fff;
  border-radius: 15px;
  width: 49% !important;
  max-width: 49% !important;
}
.main-holder-container h2 {
  font-size: 13px;
  text-align: right;
  color: grey;
  padding: 0;
}
.main-holder-container .grid-amount {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
}
.highcharts-title {
  fill: grey !important;
  font-weight: bold;
  font-size: 14px !important;
  width: 100% !important;
}
.card-icon.card-1 {
  background: #2f2e34;
}
.card-icon.card-2 {
  background: #368eee;
}
.card-icon.card-3 {
  background: #4ea851;
}
.card-icon.card-4 {
  background: #dd2467;
}
.card-icon {
  border-radius: 10px;
  position: absolute;
  left: 30px;
  background: #8a8383;
  color: #fff;
  z-index: 99;
  top: -16px;
  text-align: center;
  align-items: center;
  padding: 12px 16px;
}

/* .top-header p {
  width: 50%;
} */
.burger-menu-icon-div .burger-menu-icon {
  padding: 0;
  margin-right: 10px;

  color: #145988;
  font-weight: bold;
  padding: 0;
  margin-right: 10px;
}
.export-pdf-btn .icon-chip {
  border-radius: 6px;
  background: #192733;
  color: #fff;
  padding: 17px 0;
  width: 70px;
  font-size: 14px;
}
.pdf_options ul li {
  font-size: 13px;
}
.bg-main {
  background-color: #003a63;
}
.statement-table-data .tr:nth-child(odd) {
  background: #fff;
}
.statement-table-data .tr:hover {
  background: #f4f6f9;
  cursor: pointer;
}

.statement-table-data .data-input.text-right-amount {
  text-align: right;
}

.bg-error {
  background-color: #e74c3c;
}

.bg-primary {
  background-color: #41a6d5;
}

.bg-secondary {
  background-color: #2a719f;
}

.bg-tertiary {
  background-color: #ffbb12;
}
.MuiGrid-root .th {
  font-weight: bold;
}
.MuiGrid-root .td,
.MuiGrid-root .th {
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
  font-size: 12px;
  margin-left: -1px;
}
.th.noselect:first-child {
  border-radius: 4px 0 0 0;
}
.th.noselect:last-child {
  border-radius: 0 4px 0 0;
}
.bg-gradient {
  /* background: linear-gradient(
    90.01deg,
    #003a63 0.01%,
    #0d4e7b 50%,
    #185c8b 99.99%
  ); */
  background-color: #192733 !important;
}

.bg-new-color{
  background: #192733;
  max-height: 70px;
}

.bg-icon {
  background-color: #979797;
}

.chip {
  color: #a8a9ad;
  border: 1px solid #a8a9ad;
  border-radius: 18.5px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  padding: 8px 12px;
}
.date-picker-div input,
#demo-simple-select,
#demo-simple-select-pdf-type {
  color: #666666;
  padding: 10px 7px;
  font-size: 14px;
}
#demo-simple-select-pdf-type {
  min-width: 50px;
  padding: 8px 13px;
}
#demo-simple-select {
  padding: 8px 13px;
  min-width: 120px;
}
.transactions-filters-main div.icon-chip:last-child {
  min-width: 200px;
}

.chip-clicked {
  background: #192733;
  border: 1px solid #192733;
  color: #fff;
}
.transactions-main-form .MuiCard-root {
  overflow: visible;
}
.chip:hover {
  cursor: pointer;
}

.icon-chip {
  height: 30px;
  border: 1px solid #192733;
  box-sizing: border-box;
  border-radius: 18.5px;
  font-size: 14px;
  line-height: 16px;
  color: #192733;
  font-family: Inter;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 15px 12px;
  background-color: #fff;
}

.icon-chip:hover {
  cursor: pointer;
  background: #192733;
  border: 1px solid #192733;
  color: #fff;
}

.text-buy , .text-overdue {
  color: #aa1c1a;
}

.text-sell {
  color: #00b82a;
}

.text-finance , .text-due {
  color: #00ac83;
}

.text-tax {
  color: #192733;
}

.text-invest {
  color: #782ebf;
}

.text-more , .text-settled {
  color: #31bf00;
}
.bg-background-secondary{
  background-color: #192733;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.loading {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  /* width: 10px; */
}
.statement-table-data .table {
  min-width: 100% !important;
  overflow: auto;
  max-height: calc(100vh - 342px);
  border: 1px solid #fff;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

.filterBottomDrawer_resrFilter__YuIzo, .reminderFilter_resrFilter__1xdZM {
  width: 140px !important;
}
.filterBottomDrawer_container__FF4Pq, .reminderFilter_container__1R3qN{
  justify-content: flex-start !important;
  padding: 24px 24px 24px 50px !important;
}

.MuiDrawer-paperAnchorBottom{
  left: 50% !important;
  right: unset !important;
  max-width: 600px;
  width: 100%;
  transform: translateX(-50%) !important;
}

.transactions_deleteCancelBtn__x9GlZ{
  padding-right: 20px !important;
}

.transactions_downloadBtn__57V53, .transactions_downloadBtn__57V53, .transactions_deleteButton__2cpFO  {
  height: 42px !important;
  font-weight: 500 !important;
}
.CustomStyleButton-root-9{
  padding: 10px 0px !important;
}

.WithStyles\(ForwardRef\(Button\)\)-root-4, .WithStyles\(ForwardRef\(Button\)\)-root-11{
  padding: 10px 0px !important;
  font-weight: 500 !important;
}
.icon-green:hover{
  background-color: #1bb55c;
  border-color: #1bb55c;
}
.icon-green.disable-chip:hover{
  background-color: #ddd;
  border-color: #003a63;
}
.filterBottomDrawer_applyFilter__2lHsp {
  padding: 10px !important;
}
/* mui filed focus */

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
	border-color: #41a6d5!important;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused{
	color: #41a6d5;
}

.icon-chip .Chip-title-2 {
  visibility: hidden;
}
.icon-chip:hover .Chip-title-2 {
  visibility: visible;
}
.linkChip.icon-chip .Chip-title-2 {
  left: auto;
  right: 0 !important;
  transform: translate(0px, 0px);
}

.editHeadWrapper .back-button-wrap{
  border-radius: 40px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 12px 5px 8px;
  margin-bottom: 10px;
  background: #192733;
  color: #fff;
}

.editHeadWrapper .addToLink-button{
  float: right;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 12px 5px 8px;
  margin-bottom: 10px;
  background: #192733;
  color: #fff;
}

.editHeadWrapper .transactions_headingLink__3oXgZ .MuiInputBase-root.MuiInputBase-formControl {
  height: 35px;
}

.transasctionForLink_actionAreaContainer__1DMpS .MuiGrid-item {
  align-items: flex-start;
}
.MuiGrid-root.bg-background.transasctionForLink_actionAreaContainer__1DMpS.MuiGrid-container .MuiGrid-item {
  flex-basis: 50px;
  margin: 0 !important;
}

.MuiGrid-root.bg-background.transasctionForLink_actionAreaContainer__1DMpS.MuiGrid-container .MuiGrid-container.MuiGrid-grid-xs-9 {
  flex-basis: calc(100% - 50px);
  max-width: calc(100% - 50px);
  padding-left: 12px;
}

.transasctionDetailCard_actionAreaContainer__CCu4n .icon_bxsm.MuiGrid-item img {
  height: auto;
}

.editHeadWrapper .back-button-wrap:hover, .editHeadWrapper .addToLink-button:hover{
  background: #41a6d5;
  color: #fff;
}

.editHeadWrapper .MuiSvgIcon-root{
  margin-right: 3px;
}
.transasctionDetailCard_actionAreaContainer__CCu4n.MuiGrid-container {
  align-items: flex-start;
}
.icon_bxsm.MuiGrid-grid-xs-2 {
  flex-basis: 50px;
  max-width: 50px;
}
.right_detail_tr.MuiGrid-grid-xs-10 {
  flex-basis: calc(100% - 50px);
  max-width: calc(100% - 50px);
  padding-left: 12px;
}

.right_detail_rm.MuiGrid-grid-xs-10 {
  flex-basis: calc(100% - 10px);
  max-width: calc(100% - 10px);
  padding-left: 12px;
}

@media only screen and (min-width:600px) {
  .woot-widget-bubble.woot-elements--right {
    transform: translateX(-50%) !important;
    left: calc(50% + 260px);
    right: auto !important;
  }
  .woot-widget-holder.woot-elements--right {
    right: auto !important;
    left: calc(50% + 90px);
    transform: translateX(-50%);
  }
  .woot-widget-bubble {
    bottom: 35px !important;
  }
}



@media only screen and (max-width: 767px) {
.btn_tabs_list {
  flex-wrap: wrap;
}
.btn_tabs_list button {
  min-width: 45%;
  border-radius: 50px !important;
  margin: 4px;
  height: 45px !important;
}
.statement-table-data .table {
  max-height: unset;
  padding-bottom: 80px;
}
.transasctionDetailCard_actionAreaContainer__CCu4n .icon_bxsm.MuiGrid-item {
  align-items: center;
  justify-content: center;
}

}

@media only screen and (max-width: 600px) {
  #menu-creditAccount ul li > span,
  #menu-debitLabel ul li > span,
  #menu-customerName ul li > span,
  #menu-debitAccount ul li > span,
  #menu-creditLabel ul li > span {
    padding: 0;
    padding-left: 2px;
  }
  .transactions-main-form label {
    font-size: 14px !important;
    left: 4px;
  }
  .date-picker-div input,
  #demo-simple-select {
    padding: 6px 13px;
  }
  #cutomers-select,
  #credit-amt-checkbox,
  #debit-accounts-select,
  #debit-labels-select,
  #credit-label-select {
    padding: 5px;
    font-size: 10px;
  }
  #menu-creditAccount > div.MuiPaper-root.MuiMenu-paper,
  #menu-debitLabel div.MuiPaper-root.MuiMenu-paper,
  #menu-customerName div.MuiPaper-root.MuiMenu-paper,
  #menu-debitAccount div.MuiPaper-root.MuiMenu-paper,
  #menu-creditLabel div.MuiPaper-root.MuiMenu-paper {
    min-width: 80%;
  }
  #menu-creditAccount ul li,
  #menu-debitLabel ul li,
  #menu-customerName ul li,
  #menu-debitAccount ul li,
  #menu-creditLabel ul li {
    min-height: auto;
    padding: 3px 0;
  }
  #menu-creditAccount ul li > div > span,
  #menu-debitLabel ul li > div > span,
  #menu-customerName ul li > div > span,
  #menu-debitAccount ul li > div > span,
  #menu-creditLabel ul li > div > span {
    font-size: 12px;
  }

  .trans-filter-outer .transactions-filters-main {
    max-width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .main-holder-container .card-icon {
    left: 10px;

    padding: 8px 10px;
  }
  .top-header p {
    font-size: 15px;
  }
  .dropdowns-div-outer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 20px;
  }
  .pdf_options > div {
    width: 97%;
  }
  .export-pdf-btn > div {
    width: 95% !important;
  }
  .dropdowns-div-outer > div {
    flex: 45%;
  }
  .woot-widget-bubble.woot-elements--right {
      margin-bottom: 30px !important;
      right:15px !important
  }
  .transactions_downloadBtn__57V53, .transactions_downloadBtn__57V53, .transactions_deleteButton__2cpFO  {
    height: auto !important;
    font-weight: 500 !important;
    min-width: initial !important;
    padding: 6px !important;
    line-height: 1.5 !important;
    letter-spacing: -.6px;
  }
  .transactions_deleteButton__2cpFO,
  .transactions_cancelButton__2R5hg {
    width: initial !important;
  }
  .transactions_deleteCancelBtn__x9GlZ {
      grid-column-gap: 6px;
      column-gap: 6px;
  }
  .top-header .main-log-img{
    max-width: 170px;
    height: auto;
    max-height: 25px;
  }
  .top-header p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top-header {
    justify-content: flex-start;
  }

}

@media only screen and (max-width: 400px) {
  .btn_tabs_list button{
    font-size: 14px !important;
    line-height: 1.2;
  }
}

@media only screen and (max-width:329px){
  .top-header .main-log-img{
    max-width: 140px;
    right: 10px;
  }

}


.selected.transactions_scrollContainer__2v_fS {
  height: calc(100vh - 434px) !important;
}
.main-log-img {
  height: auto;
  max-height: 35px;
  width: 200px;
}

.profileModalOpen .woot-widget-bubble {
  bottom: 125px !important;
  margin-bottom: 0 !important;
}

.editHeadWrapper {
  position: relative;
  top: -15px;
}

.editHeadWrapper ~ .transactions_scrollContainer__2v_fS {
  height: calc(100vh - 459px) !important;
}
.editHeadWrapper ~ .transactions_scrollContainer__2v_fS.selected {
  height: calc(100vh - 499px) !important;
}


.pdf_options .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline legend.css-1in441m,
.pdf_options .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline legend.css-1in441m span {
  color: #666666 !important;
  opacity: 1;
  overflow: visible;
  top: -3px;
  position: relative;
}

/* Cashbox CSS Starts */

.cashbox-holder .cashbox-inner .cashboxItem {
  padding: 18px 14px;
  border: 1px solid #ebecf0;
  border-radius: 2px;
  background: #fff;
  margin: 0px 0 30px;
}
.cashbox-holder .cashbox-inner .cashboxItem .cashbox-heading {
  font-size: 1.25rem;
  color: #192733;
  font-weight: 600;
}
.contbx-holder .statInfo {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.contbx-holder .statInfo h4,
.contbx-holder .statInfo h5,
.contbx-holder .statInfo h6 {
  font-size: 16px;
  font-weight: 600;
  color: #57585a;
}
.contbx-holder .statInfo p {
  font-size: 24px;
  color: #a8a9ad;
}
.colapsebx {
  padding: 10px 0 0;
}
.colapsebx .icnRow {
  font-size: 16px;
  font-weight: 600;
  color: #192733;
  display: flex;
  align-items: center;
}
.colapsebx .icnRow.icon-right {
  justify-content: flex-end;
}


.numericRow_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #57585a;
  margin-top: 10px;
}
.numericRow_holder h6,
.numericRow_holder p {
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
}
.cashboxItem .contbx-holder .frmgroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
}
.cashboxItem .contbx-holder .frmgroup label {
  font-size: 14px;
  color: #636363;
  font-weight: 500;
}
.cashboxItem .contbx-holder .frmgroup input {
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
  height: 40px;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  position: relative;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.1876em;
  padding: 10px;
  min-width: 220px;
}



/* Media Query Start */

@media only screen and (max-width:767px){
  .editHeadWrapper {
    top: -10px;
  }
  .editHeadWrapper ~ .transactions_scrollContainer__2v_fS,
  .editHeadWrapper ~ .transactions_scrollContainer__2v_fS.selected,
  .selected.transactions_scrollContainer__2v_fS {
    height: auto !important;
  }
  .selected.transactions_scrollContainer__2v_fS {
    padding-bottom: calc(80px + 50px) !important;
  }
  .transactions_dialogContainerLink__1hr_H .transactions_scrollContainer__2v_fS {
    padding-bottom: 0;
  }
  
}

@media only screen and (max-width:600px){
  .transactions_dialogContainerLink__1hr_H {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .transactions_dialogContainerLink__1hr_H .transasctionForLink_container__2vOqh .MuiIconButton-root.PrivateSwitchBase-root-4.MuiCheckbox-root {
    padding: 0;
  }
  .transactions_dialogContainerLink__1hr_H .transactions_scrollContainer__2v_fS .transasctionForLink_actionAreaContainer__1DMpS .MuiGrid-container.MuiGrid-grid-xs-9 {
    flex-basis: 100% !important;
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-top: 10px;
}
.cashboxItem .contbx-holder .frmgroup input {
  min-width: 160px;
}
}

.error-message .MuiPaper-root {
  background-color: #e82b2b;
  color: #fff;
  font-weight: bold;
}
.error-container {
  text-align: center;
  height: 100vh;
  background-color: #f4f6f9;
  padding-top: 90px; 
}
.error-container .error-msg {
  font-size: 16px;
  margin: 5px 5px 20px 5px;
}
.error-container .error-back-btn {
  padding: 5px;
}

#physicalCashMissing {
  background-color: #c30909;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  color:#fff
}

#physicalCashSurplus {
  background-color: #7cb5ec;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  color:#fff
}

@media only screen and (max-width:500px){
  .icon_bxsm{
    max-width: 50px !important;
    flex-basis: 50px !important;
    width: 100%;
    height: 50px;
  }
  .right_detail_tr{
    max-width: calc(100% - 50px) !important;
    flex-basis: calc(100% - 50px) !important;
  }
  
}